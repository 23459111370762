.as-events-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.as-events-list__item {
  width: 50%;
  padding: 10px;

  @include respond-to(tablet) {
    width: 100%;
  }
}

.as-events-list-item {
  overflow: hidden;
  display: block;
  width: 100%;
  border-radius: 10px;
  border: 1px solid $as-c_main;
}

.as-events-list-item-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 10px;
  color: #fff;
  background: $as-c_main;
}

.as-events-list-item-header__item {
  margin: 2px 0;
}

.as-events-list-item__main {
  display: flex;
  align-items: center;
  padding: 15px;

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

.as-events-list-item__img-wrap {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-left: 15px;
  border: 5px solid #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  @include circle-element(135px);
}

.as-events-list-item__img {
  width: 100%;
}

.as-events-list-item__title {
  margin: 0;
  font-size: 1.7em;
  color: $as-c_main;
}

.as-events-list-item__subtitle {
  margin: 0;
  font-size: 1.2em;
  color: #A3A3A3;
}

.as-events-list-item__description {
  margin: 10px 0;
}

.as-events-list-item__btn {
  padding: 7px 18px;
  font-size: 1em;
  font-weight: 600;
}