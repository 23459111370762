.as-locations-menu-tabs {
  position: relative;
  display: block;
  overflow-y: auto;
  white-space: nowrap;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba($as-c_main, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: $as-c_main;
    border-radius: 4px;
  }
}

.as-locations-menu-tabs__btn {
  margin: 0 5px;
  padding: 8px 30px;
  font-weight: 600;
  color: #A0A0A0;
  background: transparent;
  transition: ease .15s;

  @include respond-to(tablet) {
    margin: 0 2px;
    padding: 8px 20px;
  }

  &:hover {
    color: #646464;
    background: #ECECEC;
  }

  &.as-active {
    color: #fff;
    background: $as-c_main;
  }
}

.as-locations-menu-btns-group {
  display: none;
  flex-wrap: wrap;
  padding: 15px 15px 0;

  &.as-active {
    display: flex;
  }

  @include respond-to(tablet) {
    padding: 15px 0 0;
  }
}

.as-locations-menu-btns-group__item {
  width: 20%;
  padding: 5px;

  @include respond-to(small-screens) {
    width: 25%;
  }

  @include respond-to(tablet) {
    width: 33.333%;
  }

  @include respond-to(small-tablet) {
    width: 50%;
  }
}

.as-locations-menu-btns-group__btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 10px 15px;
  color: $as-c_main;
  background: transparent;
  transition: ease .15s;

  @include respond-to(tablet) {
    padding: 10px;
  }

  @include respond-to(small-handhelds) {
    font-size: 0.95em;
    padding: 8px;
  }
  
  &:hover {
    color: $as-c_main_dark;
    background: rgba($as-c_main, 0.2);
  }

  > i {
    margin-left: 5px;
  }
}