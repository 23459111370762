@include font-face(Flaticon, assets/fonts/Flaticon/Flaticon, normal);

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("assets/fonts/Flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fi-person:before { content: "\f100"; }
.fi-people:before { content: "\f101"; }
.fi-arrow-up:before { content: "\f102"; }
.fi-search:before { content: "\f103"; }
.fi-link:before { content: "\f104"; }
.fi-book:before { content: "\f105"; }
.fi-instagram:before { content: "\f106"; }
.fi-box:before { content: "\f107"; }
.fi-location:before { content: "\f108"; }
.fi-location-fill:before { content: "\f109"; }
.fi-envelope-fill:before { content: "\f10a"; }
.fi-phone-fill:before { content: "\f10b"; }
.fi-clock:before { content: "\f10c"; }
.fi-linkedin:before { content: "\f10d"; }
.fi-twitter:before { content: "\f10e"; }
.fi-arrow-right:before { content: "\f10f"; }
.fi-arrow-left:before { content: "\f110"; }
.fi-calendar:before { content: "\f111"; }
.fi-sofa:before { content: "\f112"; }
.fi-google-plus:before { content: "\f113"; }
.fi-arrow-down:before { content: "\f114"; }
.fi-menu:before { content: "\f115"; }
.fi-door:before { content: "\f116"; }
.fi-youtube:before { content: "\f117"; }
.fi-facebook:before { content: "\f118"; }

$fi-Flaticon-person: "\f100";
$fi-Flaticon-people: "\f101";
$fi-Flaticon-arrow-up: "\f102";
$fi-Flaticon-search: "\f103";
$fi-Flaticon-link: "\f104";
$fi-Flaticon-book: "\f105";
$fi-Flaticon-instagram: "\f106";
$fi-Flaticon-box: "\f107";
$fi-Flaticon-location: "\f108";
$fi-Flaticon-location-fill: "\f109";
$fi-Flaticon-envelope-fill: "\f10a";
$fi-Flaticon-phone-fill: "\f10b";
$fi-Flaticon-clock: "\f10c";
$fi-Flaticon-linkedin: "\f10d";
$fi-Flaticon-twitter: "\f10e";
$fi-Flaticon-arrow-right: "\f10f";
$fi-Flaticon-arrow-left: "\f110";
$fi-Flaticon-calendar: "\f111";
$fi-Flaticon-sofa: "\f112";
$fi-Flaticon-google-plus: "\f113";
$fi-Flaticon-arrow-down: "\f114";
$fi-Flaticon-menu: "\f115";
$fi-Flaticon-door: "\f116";
$fi-Flaticon-youtube: "\f117";
$fi-Flaticon-facebook: "\f118";