.as-downloads-group-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.as-downloads-group-list-item {
  width: 20%;
  padding: 20px;
  text-align: center;

  @include respond-to(small-screens) {
    width: 25%;
  }

  @include respond-to(tablet) {
    width: 33.333%;
  }

  @include respond-to(small-tablet) {
    width: 50%;
    padding: 20px 10px;
  }
}

.as-downloads-group-list-item__icon {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  font-size: 5.5em;
  color: $as-c_main;

  @include respond-to(small-tablet) {
    font-size: 4.5em;
  }
}

.as-downloads-group-list-item__btn {
  padding: 10px 30px;
  font-size: 1.1em;
  font-weight: 600;

  @include respond-to(small-tablet) {
    padding: 8px 20px;
  }
}