.as-home-news-slider {
  padding: 45px 0;
  background: #F1F1F1;

  @include respond-to(small-tablet) {
    padding: 20px 0 30px;
  }

  .as-g__content-wrapper {
    padding: 0 80px;

    @include respond-to(small-tablet) {
      padding: 0 50px;
    }
  }
}

.as-home-news-slide__title {
  margin: 0 0 35px;
  font-size: 2.2em;
  text-align: center;
  color: $as-c_main;

  @include respond-to(small-tablet) {
    margin: 0 0 20px;
    font-size: 2em;
  }
}

.as-news-slider {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.as-news-slider-item {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.as-news-slider__btn_next,
.as-news-slider__btn_prev {
  z-index: 1;
  position: absolute;
  top: 50%;
  padding: 0;
  text-align: center;
  @include circle-element(40px);

  @include respond-to(small-tablet) {
    @include circle-element(30px);
  }
}

.as-news-slider__btn_prev {
  right: 40px;
  transform: translate(50%, -50%);

  @include respond-to(small-tablet) {
    right: 25px;
  }
}

.as-news-slider__btn_next {
  left: 40px;
  transform: translate(-50%, -50%);

  @include respond-to(small-tablet) {
    left: 25px;
  }
}

.as-news-slider-item__img-wrap {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
  border-radius: 5px;
}

.as-news-slider-item__img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.as-news-slider-item__content {
  margin-top: 10px;
}

.as-news-slider-item__title {
  margin: 0;
  font-size: 1.7em;
  
  @include respond-to(small-tablet) {
    font-size: 1.5em;
  }
}

.as-news-slider-item__date {
  font-size: 1.3em;
  font-weight: 600;
  color: #919191;

  @include respond-to(small-tablet) {
    font-size: 1.1em;
  }
}

.as-news-slider-item__description {
  margin: 10px 0 15px;
}