.as-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 1.1em;
  border-radius: 99px;
  padding: 10px 35px;
  color: #333;
  background: #ddd;
  -webkit-appearance: none;

  &:hover, &:focus {
    background: #CFCFCF;
  }
}

.as-btn_primary {
  @extend .as-btn;
  color: $as-c_sub;
  background: $as-c_main;
  transition: ease .1s;
  box-shadow: inset 0 -3px 0 0 darken($as-c_main, 10%);

  &:hover, &:focus {
    background: darken($as-c_main, 5%);
  }

  &:active {
    box-shadow: inset 0 3px 0 0 darken($as-c_main, 10%);
  }
}

.as-btn_reg {
  @extend .as-btn;
  color: $as-c_sub;
  background: $as-c_success;
  transition: ease .1s;
  box-shadow: inset 0 -3px 0 0 darken($as-c_success, 10%);

  width: 48%; 
  text-align: center;

  margin: 0 3px 0 10px;

  &:hover, &:focus {
    background: darken($as-c_success, 5%);
  }

  &:active {
    box-shadow: inset 0 3px 0 0 darken($as-c_success, 10%);
  }
}

.as-btn_cancel {
  @extend .as-btn;
  color: $as-c_sub;
  background: $as-c_error;
  transition: ease .1s;
  box-shadow: inset 0 -3px 0 0 darken($as-c_error, 10%);

  width: 48%; 
  text-align: center;

  &:hover, &:focus {
    background: darken($as-c_error, 5%);
  }

  &:active {
    box-shadow: inset 0 3px 0 0 darken($as-c_error, 10%);
  }
}

.as-btn__icon {
  font-size: 1em;
  vertical-align: middle;
}

.billy-active-button{
  top: 22% !important;
}