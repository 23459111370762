.as-main, .as-footer {
  transition: ease .2s;

  &.as-menu-open {
    filter: blur(5px);
  }
}

.as-menu {
  display: flex;

  @include respond-to(medium-screens){
    z-index: 999;
    overflow: scroll;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background: $as-bg_main;
    border-top: 5px solid $as-c_main;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    transform: translate(-100%, 0);
    transition: $easeOutQuint .4s;

    &.as-active {
      transform: translate(0, 0);
    }
  }
}

.as-menu__open-btn,
.as-menu__close-btn {
  cursor: pointer;
  color: $as-c_main;
  background: transparent;
  border: none;
}

.as-menu__open-btn {
  padding: 5px 10px;
  font-size: 2em;
  transform: rotateY(180deg);
}

.as-menu__close-btn {
  font-size: 1.3em;
  margin: 9px 0
}

.as-menu__item {
  position: relative;
  flex-shrink: 0;
  align-self: center;
  font-weight: 600;
  padding: 0 5px;
  text-align: center;

  &:hover, &:focus {
    > .as-menu__item__link {
      color: darken($as-c_main, 7%);
      background: rgba($as-c_main, 0.2);
    }
  }

  @include respond-to(medium-screens){
    width: 100%;
    height: auto;
    padding: 15px 10px 0;
    text-align: right;

    &:last-child {
      padding-bottom: 50px;
    }

    &:hover, &:focus {
      > .as-menu__item__link {
        color: $as-c_main;
        background: transparent;
      }
    }
  }
}

.as-menu__item__link {
  display: block;
  width: 100%;
  font-size: 1.1em;
  padding: 6px 12px;
  color: $as-c_main;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 99px;
  transition: ease .15s;

  @include respond-to(wide-screens){ 
    font-size: 1em;
    padding: 5px 10px;
  }

  @include respond-to(medium-screens){
    display: inline-block;
    white-space: pre-wrap;
    width: auto;
    padding: 4px 16px;
  }

  &.as-active {
    color: #fff !important;
    background: $as-c_main !important;

    &:hover, &:focus {
      color: #fff;
      background: $as-c_main_dark;
    }
  }
}

.as-menu__item__link_primary {
  border-color: $as-c_main;
}

.as-menu__submenu {
  opacity: 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 25px);
  right: 0;
  min-width: 100%;
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.95);
  transform: translate(0, 20px);
  transition: ease .15s;
  transition-delay: 0.25s;
  

  @include respond-to(medium-screens){
    position: static;
    padding: 0 10px;
    max-width: 100%;
    max-height: 0px;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(0, 10px);
    transition: all ease .2s, max-height ease .4s;

    &.as-active {
      padding: 10px;
      margin-top: 10px;
    }
  }

  .as-menu__item {
    width: 100%;
    padding: 2px 0;

    &:first-child {
      border-top: none;
    }

    .as-menu__item__link {
      font-size: 1.1em;
      white-space: pre-wrap;
      padding: 7px 10px;

      &:hover, &:focus {
        color: $as-c_main;
        background: rgba($as-c_main, 0.2);
      }

      @include respond-to(medium-screens){
        display: inline-block;
        white-space: pre-wrap;
        width: auto;
        padding: 4px 16px;
        font-size: 1.1em;

        &:hover, &:focus {
          background: transparent;
        }
      }
    }
  }
}

.as-menu__submenu .as-menu__submenu {
  top: 0;
  right: calc(100% + 20px);
  border-bottom: none;
}

.as-menu__item:hover > .as-menu__submenu, 
.as-menu__item > .as-menu__submenu.as-active {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate(0, 0);

  @include respond-to(medium-screens){
    transition-delay: 0s;
    transition: all ease .4s, max-height ease .5s;
  }
}

.as-menu__item:hover > .as-menu__submenu:not(.as-active) {
  @include respond-to(medium-screens){
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    transform: translate(0, 0);
  }
}

.as-menu-active-btn {
  display: none;
  float: left;
  text-align: center;
  font-size: 1em;
  color: $as-c_main_dark;
  @include circle-element(28px);
  
  @include respond-to(medium-screens){
    display: block;
  }

  &.as-active {
    i {
      transform: rotate(180deg);
    }
  }

  i {
    display: block;
    transition: ease .2s;
  }
}

.as-menu__form_search {
  .as-form__input {
    background: rgba($as-c_main, 0.15);
    border: none;
  }

  .as-form__submit {
    position: absolute;
    top: 50%;
    left: 5px;
    padding: 0;
    font-size: 1.1em;
    border: 1px solid $as-c_main;
    color: $as-c_main;
    background: transparent;
    transition: ease .15s;
    transform: translate(0, -50%);
    @include circle-element(30px);
  
    &:hover, &:focus {
      color: #fff;
      background: $as-c_main;
    }
  }
}

.as-menu-mask {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($as-c_main_dark, 0.5);
  transition: ease .2s;

  &.as-active {
    opacity: 1;
    visibility: visible;
  }
}