.as-category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.as-category-list__item {
  border-bottom: 1px solid #C2C2C2;

  &:last-child {
    border-bottom: none;
  }
}

.as-category-list-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;  
  min-height: 175px;
  color: $as-c_font;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: ease .2s;

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
  }

  &:hover, &:focus {
    .as-category-list-item__image {
      filter: contrast(130%);
      transform: scale(1.06);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
    }
  }
}

.as-category-list-item__image {
  position: relative;
  width: 250px;
  transition: ease .2s;

  @include respond-to(medium-screens) {
    width: 150px;
  }

  @include respond-to(small-tablet) {
    width: 100%;
  }
}

.as-category-list-item__content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: flex-start;
  align-self: stretch;
  padding: 0 20px;
  width: 100%;

  @include respond-to(small-tablet) {
    padding: 10px 0 0;
  }
}

.as-category-list-item__title {
  font-size: 1.8em;
  margin: 0;

  @include respond-to(handhelds) {
    font-size: 1.6em;
  }
}

.as-category-list-item__subtitle {
  font-size: 1.3em;
  margin: 0;
  color: #6E6E6E;

  @include respond-to(handhelds) {
    font-size: 1.1em;
  }
}

.as-category-list-item__description {
  font-size: 1.1em;
}