.as-home-artical {
  display: flex;
  justify-content: space-between;
  padding: 90px 0;
  margin: 0 -15px;

  @include respond-to(tablet){
    flex-wrap: wrap;
    margin: 0;
    padding: 25px 0;
  }

  @include respond-to(small-handhelds){
    font-size: 0.9em;
  }
}

.as-home-artical__cell {
  flex-grow: 1;
  width: 100%;
  padding: 0 15px;

  @include respond-to(tablet){
    padding: 0;
    margin-bottom: 15px;
  }
}

.as-home-artical__img-wrap {
  overflow: hidden;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 65%;
  border-radius: 5px;
}

.as-home-artical__img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.as-home-artical__title {
  margin: 0 0 20px;
  font-size: 2em;
  color: $as-c_main;
}

.as-home-artical__description {
  margin-bottom: 20px;
  text-align: right;
  font-size: 1em;
}

.as-home-artical__btn {
  padding: 10px 50px;
  font-size: 1.1em;
  font-weight: 600;
}