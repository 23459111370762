.as-persons-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.as-persons-list__item {
  width: 50%;
  padding: 10px;

  @include respond-to(tablet) {
    width: 100%;
  }
}

.as-persons-list-item {
  overflow: hidden;
  display: block;
  width: 100%;
  border-radius: 10px;
  border: 1px solid $as-c_main;
}

.as-persons-list-item__main {
  display: flex;
  align-items: center;
  padding: 15px;

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

.as-persons-list-item__img-wrap {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-left: 15px;
  border: 5px solid #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  @include circle-element(135px);
}

.as-persons-list-item__img {
  width: 100%;
}

.as-persons-list-item__title {
  margin: 0;
  font-size: 1.7em;
  color: $as-c_main;
}

.as-persons-list-item__subtitle {
  margin: 0;
  font-size: 1.2em;
  color: #A3A3A3;
}

.as-persons-list-item__description {
  margin: 10px 0;
}

.as-persons-list-item__footer {
  padding: 15px;
  text-align: left;
  background: $as-c_main;
}

.as-persons-list-item__btn {
  display: inline-block;
  padding: 5px 25px;
  font-size: 1.25em;
  border-radius: 99px;
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: ease .15s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}