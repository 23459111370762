.as-content-block__img {
  float: right;
  width: 450px;
  margin-left: 35px;
  margin-bottom: 25px;

  @include respond-to(tablet) {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}