.as-footer-newsletter {
  width: 100%;

  .as-form__label {
    font-weight: 700;
    color: #fff;
  }

  @include respond-to(tablet){
    margin-top: 25px;

    .as-form__row_margin_end {
      flex-wrap: wrap;
      justify-content: center;
    }
    .as-form__cell_grow {
      margin-bottom: 10px;
    }
    .as-form__submit {
      margin-top: 15px;
    }
  }
}

.as-footer-newsletter__title {
  font-size: 2.2em;
  font-weight: 700;
  color: $as-c_main;

  @include respond-to(tablet){
    font-size: 1.8em;
  }
}

.as-footer-newsletter__content {
  margin-bottom: 5px;
  font-size: 1.3em;
  color: #fff;

  @include respond-to(tablet){
    font-size: 1.1em;
  }
}

.as-footer-newsletter__form {
  padding: 10px 0;
}