.as-footer {
  position: relative;
  width: 100%;
}

.as-footer__top {
  background: #333;
}

.as-footer__bottom {
  background: #fff;
  border-bottom: 8px solid $as-c_main;
}

.as-footer-logo {
  display: inline-block;
  max-width: 175px;
}

.as-footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  &:first-child {
    border-top: none;
  }
}

.as-footer-cell {
  align-self: center;

  @include respond-to(tablet){
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
}

.as-footer-copyrights {
  direction: ltr;
  width: 100%;
  text-align: center;
  font-size: 1em;

  > * {
    vertical-align: middle;
  }

  @include respond-to(tablet) {
    text-align: center;
  }
}

.as-footer-copyrights__symbol {
  font-weight: 600;
}

.as-footer-copyrights__logo {
  display: inline-block !important;
  width: 60px;
}

.as-footer-social-btn {
  color: $as-c_main;
  margin: 0 8px;

  &:hover, &:focus {
    color: $as-c_main_dark;
  }
}

.as-footer-logos-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
}

.as-footer-logos-menu-item {
  max-width: 225px;
  margin-left: 30px;
}

.as-footer-logos-menu-item__img {
  width: 100%;
}

.as-footer-gotop-btn {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  font-size: 2em;
  transform: translate(0, -50%);
  @include circle-element(70px);

  &:active {
    transform: translate(0, calc(-50% + 3px));
  }

  @include respond-to(tablet){
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6em;
    @include circle-element(55px);

    &:active {
      transform: translate(-50%, calc(-50% + 3px));
    }
  }
}