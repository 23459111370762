.as-persons-search-panel {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
}

.as-persons-search-panel__title {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0;
  padding: 0 20px;
  color: $as-c_main;
  background: #fff;
  transform: translate(-50%, -50%);
}

.as-persons-search-panel__form {
  max-width: 475px;
  margin: 20px auto;

  @include respond-to(handhelds) {
    .as-form__row_margin_end {
      flex-wrap: wrap;
      justify-content: center;
    }

    .as-form__cell, .as-form__cell_grow {
      margin-bottom: 15px;
    }
  }
}