.as-home-numerator {
  background: #fff;
  padding: 30px 0;

  @include respond-to(small-tablet) {
    padding: 30px 0 60px;
  }
}

.as-home-numerator__wrapper {
  display: flex;
  justify-content: space-around;

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
  }
}

.as-home-numerator-item {
  flex-grow: 1;
  width: 100%;
  text-align: center;
  color: #5F5F5F;

  @include respond-to(small-tablet) {
    margin-bottom: 20px;
    font-size: 0.9em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.as-home-numerator-item__icon {
  font-size: 2em;
}

.as-home-numerator-item__label {
  font-size: 1.4em;
  font-weight: 700;
}

.as-home-numerator-item__number {
  color: $as-c_main;
  font-size: 3.6em;
  line-height: 1em;
  font-weight: 700;
}