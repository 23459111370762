.as-content {
  font-size: 1em;
  line-height: 1.5em;

  @include respond-to(tablet) {
    font-size: 1em;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $as-c_main;
  }

  iframe {
    border-width: 0;
  }
  
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ddd;
    margin: 1em 0;
    padding: 0; 
  }
  
  p {
    margin-top: 0;
    margin: 0.5em 0;
  }
  
  a {
    color: $as-c_href;
    font-weight: 700;
    text-decoration: underline;
    
    &:hover, &:focus {
        color: $as-c_href_hover;
    }
  }
  
  table {
    width: 100%;
    padding-bottom: 10px;
    border-collapse: collapse;

    @include respond-to(medium-screens) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }

		&::-webkit-scrollbar {
      width: 7px;
			height: 7px;
		}
		
		&::-webkit-scrollbar-track {
      background: #DBDBDB;
		}
		
		&::-webkit-scrollbar-thumb {
      background: #B6B6B6;
			border-radius: 7px;
    }
    
    th {
      padding: 7px 14px;
      text-align: right;
      font-size: 1.2em;
      border-bottom: 2px solid $as-c_main;
    }
    
    td {
      padding: 12px 14px;
      border-bottom: 1px solid $as-c_line_light;
      
      p {
        margin-bottom: 5px;
      }
    }
  }  
}