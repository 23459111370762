html, body {
  display: flex;
  flex-direction: column;
	min-height: 100vh;
}

.as-main {
  flex: 1 0 auto;
}

.as-g__wrapper,
.as-g__content-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.as-g__wrapper {
  max-width: $as-g__max-width;
}

.as-g__content-wrapper {
  max-width: $as-g__content-max-width;
}

.as-g__page_padding {
  padding: 10px 0 90px;
}