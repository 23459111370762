.as-search-panel {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.as-search-panel__btn {
  padding: 0;
  font-size: 1.5em;
  border: 1px solid $as-c_main;
  color: $as-c_main;
  background: transparent;
  @include circle-element(40px);
  transition: ease .15s;

  &:hover, &:focus {
    background: rgba($as-c_main, 0.2);
  }

  &.as-active {
    color: #fff;
    background: $as-c_main;
  }

  @include respond-to(medium-wide-screens){ 
    font-size: 1.2em;
    @include circle-element(40px);
  }
}

.as-search-panel__prompt {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  white-space: nowrap;
  position: absolute;
  top: 100%;
  left: 5px;
  min-width: 450px;
  padding: 10px;
  border-radius: 99px;  
  background: rgba($as-c_main, 0.5);
  transform: translate(0, 0);
  transition: $easeOutQuint .35s;

  &.as-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 25px);
  }
}