.as-search-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.as-search-list-item {
  width: 50%;
  padding: 10px;

  @include respond-to(small-tablet) {
    width: 100%;
  }
}

.as-search-list-item__wrapper {
  display: block;
  padding: 10px;
  color: $as-c_font;
  border-radius: 5px;
  border: 1px solid rgba($as-c_main, 0.4);
  transition: ease .15s;

  &:hover, &:focus {
    border-color: rgba($as-c_main, 0.7);
    background: rgba($as-c_main, 0.05);
  }
}

.as-search-list-item__title {
  margin: 0;
  font-size: 1.5em;
  color: $as-c_main;

  @include respond-to(small-tablet){
    font-size: 1.2em;
  }
}

.as-search-list-item__subtitle {
  margin: 0;
  font-size: 1.2em;
  font-weight: 600;
  color: #646464;

  @include respond-to(small-tablet){
    font-size: 1.1em;
  }
}

.as-search-list-item__description {
  margin: 8px 0 0;
}