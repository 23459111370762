.as-form__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.as-form__row_margin {
  @extend .as-form__row;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 20px;
}

.as-form__row_margin_end {
  @extend .as-form__row_margin;
  align-items: flex-end;
}

.as-form__row_margin_center {
  @extend .as-form__row_margin;
  align-items: center;
}

.as-form__row_wrap {
  @extend .as-form__row;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.as-form__row_submit {
  @extend .as-form__row;
  justify-content: flex-end;

  @include respond-to(tablet) {
    justify-content: center;
  }
}

.as-form__cell {
  position: relative;
  margin: 0 10px;
}

.as-form__cell_grow {
  @extend .as-form__cell;
  width: 100%;
  flex-grow: 1;
}

.as-form__cell_nowrap {
  @extend .as-form__cell;
  white-space: nowrap;
}

.as-form__link {
  display: block;
  color: #7d7d7d;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

.as-form__label {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 3px;
  font-size: 1.1em;
  font-weight: 600;
  color: $as-c_main;
}

.as-form__label_required {
  @extend .as-form__label;

  &:before {
    content: '*';
    color: $as-c_main;
  }
}

.as-form__label_row {
  @extend .as-form__label;
  display: block;
  margin-bottom: 8px;
}

.as-form__label_no-margin {
  @extend .as-form__label;
  margin: 0;
}

.js {
  .as-form__file-input {
    display: none;
  }
}

.as-form__file-input__btn {
  cursor: pointer;
  display: inline-block;
  padding: 6px 15px;
  border-radius: 99px;
  background: #E4E4E4;

  &:hover, &:focus {
    background: #D3D3D3;
  }

  &.as-active {
    color: #fff;
    background: $as-c_main;

    &:hover, &:focus {
      background: $as-c_main_dark;
    }
  }
}

.as-form__file-input__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 250px;
  margin: 0 5px;
  padding: 0 10px;
  color: #888888;

  @include respond-to(handhelds) {
    max-width: 175px;
  }

  &.as-active {
    padding: 6px 15px;
    color: #333;
    border-radius: 99px;
    border: 1px solid #ddd;
  }
}

.as-form__file-input__icon {
  margin-left: 5px;
}

.as-form__input {
  width: 100%;
  border: none;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 1.1em;
  border-radius: 99px;
  padding: 8px 10px;
  background: #fff;
  border-bottom: 3px solid #ddd;
  -webkit-appearance: none;
}

.as-form__textarea {
  @extend .as-form__input;
  resize: vertical;
  border-radius: 10px;
}

.as-form__textarea_border {
  @extend .as-form__textarea;
  border: 1px solid #ddd;
  box-shadow: 0 2px 0 0 #ddd
}

.as-form__input_border {
  @extend .as-form__input;
  border: 1px solid #ddd;
  box-shadow: 0 2px 0 0 #ddd
}

.as-form__input-wrap {
  position: relative;
  padding: 0;
  @extend .as-form__input;
  
  &:hover, &:focus {
    .as-form__input-icon {
      background: $as-c_main_dark;
    }
  }

  .as-form__input {
    cursor: pointer;
    z-index: 1;
    background: transparent;
    position: relative;
  }
}

.as-form__input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40px;
  font-size: 1.5em;
  border-radius: 99px 0 0 99px;
  color: #fff;
  background: $as-c_main;
  transition: ease .15s;
}

.as-form__select {
  @extend .as-form__input;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  min-width: 150px;
  padding: 9px 10px 9px 50px;
  background-image: url('data:image/svg+xml;utf8,\
  <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"\
	  x="0px" y="0px" viewBox="0 0 29.4 29.4" xml:space="preserve">\
	  <path fill="#{$as-c_main}" d="M14.7,0C6.6,0,0,6.6,0,14.7s6.6,14.7,14.7,14.7s14.7-6.6,14.7-14.7S22.8,0,14.7,0C14.7,0,14.7,0,14.7,0z\
		  M24.9,11.4l-9.7,9.7c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2l-9.7-9.7c-0.2-0.4-0.1-0.8,0.3-1c0.2-0.1,0.5-0.1,0.7,0\
      l9.2,9.2l9.2-9.2c0.3-0.3,0.7-0.3,1,0C25.2,10.6,25.2,11.1,24.9,11.4z"/>\
  </svg>\
  ');
  background-position: 10px 50%;
  background-size: 15px;
  background-repeat: no-repeat;
}

.as-form__select_border {
  @extend .as-form__select;
  border: 1px solid #ddd;
  box-shadow: 0 2px 0 0 #ddd
}

.as-form__checkbox-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  font-size: 1em;
  font-weight: 600;
}

.as-form__checkbox {
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  background: $as-c_main;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
	border-radius: 99px;
  border: 10px solid #fff;
  transition: $easeOutQuint .2s;
  
  &:checked {
    padding: 4px;
    border: 4px solid #fff;
  }
}

.as-form__checkbox_border {
  @extend .as-form__checkbox;
  border: 8px solid #ddd;

  &:checked {
    border: 4px solid #ddd;
  }
}

.as-form__fieldset {
  border-color: #C0C0C0;
  border-radius: 5px;
}

.as-form__submit {
  white-space: nowrap;
  padding: 10px 45px;
  font-weight: 700;
  font-size: 1.2em;
}

.as-form__message {
  width: 100%;
  padding: 8px 10px;
  font-weight: 600;
  border-radius: 99px;
  color: #555555;
  background: #EEEEEE;

  @include respond-to(small-tablet){
    font-size: 0.9em;
  }
}

.as-form__message_success {
  @extend .as-form__message;
  text-align: center;
  color: #fff;
  background: $as-c_success;
}

.as-form__message_error {
  @extend .as-form__message;
  text-align: center;
  color: #fff;
  background: $as-c_error;
}

.as-form_popup{
  width: 50%; 
  margin: 0px auto 15px auto; 
}