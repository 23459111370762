.as-header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 5px solid $as-c_main;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.95);
}

.as-header-wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 15px;

  @include respond-to(tablet) {
    flex-wrap: nowrap;
    padding: 10px;
  }
}

.as-header-cell {
  position: relative;
  height: 100%;
  margin: 0 10px;

  @include respond-to(medium-wide-screens){ 
    margin: 0 5px;
  }

  @include respond-to(medium-screens){ 
    margin: 0;
  }
}

.as-header-cell_logo {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  margin-left: 10px;

  @include respond-to(medium-screens) {
    width: auto;
    margin-bottom: 0;
  }
}

.as-header-logo {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}