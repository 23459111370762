.as-testimonials-slider {
  overflow: hidden;
  position: relative;
  height: 600px;

  .swiper-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
  }

  .swiper-pagination {
    bottom: 50px;

    @include respond-to(small-tablet) {
      bottom: 25px;
    }
  }

  .swiper-pagination-bullet {
    opacity: 1;
    width: 14px;
    height: 14px;
    border: 1px solid rgba(51, 51, 51, 0.6);
    background: transparent;
    transition: ease .15s;
  }

  .swiper-pagination-bullet-active {
    border: 1px solid $as-c_main;
    background: $as-c_main;
  }
}

.as-testimonials-slider__parallax {
  position: relative;
  width: 120%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.as-testimonials-slider-item {
  display: block;
  padding: 80px 40px;

  @include respond-to(small-tablet) {
    padding: 40px 5px;
  }
}

.as-testimonials-slider-item__img-wrap {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: 6px solid #fff;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);  
  @include circle-element(175px);

  @include respond-to(small-tablet) {
    border: 5px solid #fff;
    @include circle-element(155px);
  }
}

.as-testimonials-slider-item__img {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.as-testimonials-slider-item__content {
  margin-top: 5px;
}

.as-testimonials-slider-item__title {
  margin: 0;
  font-size: 1.8em;
  color: $as-c_main;
}

.as-testimonials-slider-item__subtitle {
  margin: 0;
  font-size: 1.2em;
}

.as-testimonials-slider-item__description {
  position: relative;
  font-size: 1.1em;
  font-style: italic;
  margin: 20px 0 30px;
  padding: 0 50px;

  @include respond-to(small-tablet) {
    font-size: 1em;
  }
  

  &:before, &:after {
    display: block;
    position: absolute;
    width: 50px;
    text-align: left;
    line-height: 0.5em;
    font-size: 6em;
    color: $as-c_main;
  }

  &:before {
    content: '”';
    right: 10px;
    top: 0;
  }

  &:after {
    content: '“';
    left: -5px;
    top: 100%;
  }
}