.as-events-seach-panel {
  @include respond-to(small-tablet) {
    .as-form__row_margin_end{
      flex-wrap: wrap;
      justify-content: center;
    }
    .as-form__cell, .as-form__cell_grow {
      margin-bottom: 15px;
    }
  }
}