.as-download-content-block {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include respond-to(tablet) {
    flex-wrap: wrap;
  }
}

.as-download-content-block__cell {
  flex-grow: 1;
  width: 100%;
}

.as-download-content-block__img {
  width: 100%;
  
  @include respond-to(tablet) {
    display: none;
  }
}

.as-download-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.as-download-item__cell {
  margin: 0 5px;
}

.as-download-item__icon {
  font-size: 1.2em;
  color: $as-c_main;

  @include respond-to(handhelds) {
    font-size: 1.1em;
  }
}

.as-download-item__link {
  white-space: nowrap;
  font-size: 1.2em;
  text-decoration: underline;
  color: $as-c_main;

  &:hover {
    color: $as-c_main_dark;
  }

  @include respond-to(handhelds) {
    font-size: 1.1em;
  }
}