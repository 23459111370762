.as-home-slider {
  overflow: hidden;
  position: relative;
  width: 100%;


  .swiper-pagination-bullet {
    opacity: 1;
    width: 14px;
    height: 14px;
    border: 1px solid #fff;
    background: transparent;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    transition: ease .15s;
  }
  
  .swiper-pagination-bullet-active {
    background: #fff;
  }
}

.as-home-slider-item {
  overflow: hidden;
  position: relative;
  width: 100%;

  @include respond-to(small-screens) {
    height: 500px;
  }
}

.as-home-slider__btn_next,
.as-home-slider__btn_prev {
  opacity: 0.8;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  padding: 0;
  background: rgba($as-c_main, 0.3);
  @include circle-element(125px);
  transition: ease .15s;

  @include respond-to(small-tablet) {
    display: none;
  }

  &:hover, &:focus {
    opacity: 1;
    background: rgba($as-c_main, 0.7);
    @include circle-element(150px);
  }

  @include respond-to(small-screens) {
    @include circle-element(70px);

    &:hover, &:focus {
      @include circle-element(80px);
    }
  }
}

.as-home-slider__btn_next {
  left: 0;
  text-align: right;
  transform: translate(-50%, -50%);
}

.as-home-slider__btn_prev {
  right: 0;
  text-align: left;
  transform: translate(50%, -50%);
}

.as-home-slider__btn__icon {
  color: #fff;
  padding: 0 25px;
  font-size: 1.7em;

  @include respond-to(small-screens) {
    font-size: 1.4em;
    padding: 0 10px;
  }
}

.as-home-slider-item__image {
  width: 100%;

  @include respond-to(small-screens) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}

.as-home-slider-item__wrapper {
  position: absolute;
  height: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0;
}

.as-home-slider-item-box {
  width: 550px;
  margin-bottom: 45px;
  padding: 30px;
  border-radius: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.65);
  @include clearfix;

  @include respond-to(wide-screens) {
    display: block;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;
    padding-bottom: 50px;
  }

  @include respond-to(small-tablet) {
    width: 100%;
    padding: 20px 10px 50px;
    text-align: center;
    border-radius: 0;
  }
}

.as-home-slider-item-box__title {
  margin: 0;
  font-size: 2.4em;

  @include respond-to(small-tablet) {
    font-size: 2em;
  }
}

.as-home-slider-item-box__content {
  font-size: 1.1em;
  margin-bottom: 15px;

  @include respond-to(small-tablet) {
    font-size: 1em;
  }
}

.as-home-slider-item-box__btn {
  float: left;
  padding: 6px 50px;
  font-size: 1.15em;
  border: 1px solid #fff;
  color: $as-c_main;
  background: transparent;
  transition: ease .15s;

  @include respond-to(small-tablet) {
    float: none;
  }

  &:hover, &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
}